import config from 'config'
import { region } from '../../common/region/region.js'

export { requireFeature } from './requireFeature.jsx'

export const FEATURE_SELL = 'FEATURE_SELL'
export const FEATURE_RETURN_LABELS = 'FEATURE_RETURN_LABELS'
export const FEATURE_P2P = 'FEATURE_P2P'
export const FEATURE_CREDITS = 'FEATURE_CREDITS'
export const FEATURE_TYPES_PAGE = 'FEATURE_TYPES_PAGE'
export const FEATURE_STORES_PAGE = 'FEATURE_STORES_PAGE'
export const FEATURE_BAG_PICKUP = 'FEATURE_BAG_PICKUP'
export const FEATURE_DONATION_PAYOUT = 'FEATURE_DONATION_PAYOUT'
export const FEATURE_REFERRALS = 'FEATURE_REFERRALS'
export const FEATURE_HM_COLLAB_BUY = 'FEATURE_HM_COLLAB_BUY'
export const FEATURE_HM_COLLAB_SELL = 'FEATURE_HM_COLLAB_SELL'
export const FEATURE_HM_COLLAB_EXTRA_LINKS = 'FEATURE_HM_COLLAB_EXTRA_LINKS'
export const FEATURE_BAG_SUBSCRIPTION = 'FEATURE_BAG_SUBSCRIPTION'
export const FEATURE_STATEMENT = 'FEATURE_STATEMENT'
export const FEATURE_SELL_SIGNUP = 'FEATURE_SELL_SIGNUP'
export const FEATURE_XMAS_CAMPAIGN = 'FEATURE_XMAS_CAMPAIGN'
export const FEATURE_FLATTERED_COLLAB_SELL = 'FEATURE_FLATTERED_COLLAB_SELL'
export const FEATURE_KLARNA_COLLAB_BUY = 'FEATURE_KLARNA_COLLAB_BUY'
export const FEATURE_SNAPCHAT_COLLAB_BUY = 'FEATURE_SNAPCHAT_COLLAB_BUY'
export const FEATURE_PROMOTIONAL_SEARCH_FOOTER = 'FEATURE_PROMOTIONAL_SEARCH_FOOTER'
export const FEATURE_FASHIONWEEK_COLLAB_BUY = 'FEATURE_FASHIONWEEK_COLLAB_BUY'
export const FEATURE_CONTAINER_TRACKING_LINKS = 'FEATURE_CONTAINER_TRACKING_LINKS'
export const FEATURE_SALES_CONTROL = 'FEATURE_SALES_CONTROL'
export const ITEM_URL_WITHOUT_SLUG = 'ITEM_URL_WITHOUT_SLUG'

export const ALL_FEATURE_TOGGLES = [
  FEATURE_SELL,
  FEATURE_RETURN_LABELS,
  FEATURE_P2P,
  FEATURE_CREDITS,
  FEATURE_TYPES_PAGE,
  FEATURE_STORES_PAGE,
  FEATURE_BAG_PICKUP,
  FEATURE_DONATION_PAYOUT,
  FEATURE_REFERRALS,
  FEATURE_HM_COLLAB_BUY,
  FEATURE_HM_COLLAB_SELL,
  FEATURE_HM_COLLAB_EXTRA_LINKS,
  FEATURE_BAG_SUBSCRIPTION,
  FEATURE_STATEMENT,
  FEATURE_SELL_SIGNUP,
  FEATURE_XMAS_CAMPAIGN,
  FEATURE_FLATTERED_COLLAB_SELL,
  FEATURE_KLARNA_COLLAB_BUY,
  FEATURE_SNAPCHAT_COLLAB_BUY,
  FEATURE_PROMOTIONAL_SEARCH_FOOTER,
  FEATURE_FASHIONWEEK_COLLAB_BUY,
  FEATURE_CONTAINER_TRACKING_LINKS,
  FEATURE_SALES_CONTROL,
  ITEM_URL_WITHOUT_SLUG
]

export const REGION_FEATURES = {
  SE: [
    FEATURE_SELL,
    FEATURE_RETURN_LABELS,
    FEATURE_P2P,
    FEATURE_CREDITS,
    FEATURE_TYPES_PAGE,
    FEATURE_STORES_PAGE,
    FEATURE_BAG_PICKUP,
    FEATURE_DONATION_PAYOUT,
    FEATURE_HM_COLLAB_SELL,
    FEATURE_BAG_SUBSCRIPTION,
    FEATURE_STATEMENT,
    FEATURE_KLARNA_COLLAB_BUY,
    FEATURE_SNAPCHAT_COLLAB_BUY,
    FEATURE_PROMOTIONAL_SEARCH_FOOTER,
    FEATURE_FLATTERED_COLLAB_SELL,
    FEATURE_FASHIONWEEK_COLLAB_BUY,
    FEATURE_CONTAINER_TRACKING_LINKS,
    FEATURE_SALES_CONTROL
  ],
  DE: [
    FEATURE_RETURN_LABELS,
    FEATURE_STORES_PAGE,
    FEATURE_TYPES_PAGE,
    FEATURE_CREDITS,
    FEATURE_SELL,
    FEATURE_DONATION_PAYOUT,
    FEATURE_BAG_SUBSCRIPTION,
    FEATURE_HM_COLLAB_SELL,
    FEATURE_PROMOTIONAL_SEARCH_FOOTER,
    FEATURE_FLATTERED_COLLAB_SELL,
    FEATURE_FASHIONWEEK_COLLAB_BUY,
    FEATURE_SNAPCHAT_COLLAB_BUY,
    FEATURE_CONTAINER_TRACKING_LINKS,
    ITEM_URL_WITHOUT_SLUG,
    FEATURE_SALES_CONTROL
  ],
  AT: [
    FEATURE_RETURN_LABELS,
    FEATURE_STORES_PAGE,
    FEATURE_TYPES_PAGE,
    FEATURE_CREDITS,
    FEATURE_SELL,
    FEATURE_DONATION_PAYOUT,
    FEATURE_BAG_SUBSCRIPTION,
    FEATURE_HM_COLLAB_BUY,
    FEATURE_HM_COLLAB_SELL,
    FEATURE_PROMOTIONAL_SEARCH_FOOTER,
    FEATURE_FLATTERED_COLLAB_SELL,
    FEATURE_FASHIONWEEK_COLLAB_BUY,
    ITEM_URL_WITHOUT_SLUG,
    FEATURE_SALES_CONTROL
  ],
  NL: [
    FEATURE_RETURN_LABELS,
    FEATURE_STORES_PAGE,
    FEATURE_TYPES_PAGE,
    FEATURE_SELL,
    FEATURE_CREDITS,
    FEATURE_DONATION_PAYOUT,
    FEATURE_HM_COLLAB_BUY,
    FEATURE_PROMOTIONAL_SEARCH_FOOTER,
    FEATURE_FLATTERED_COLLAB_SELL,
    FEATURE_SALES_CONTROL
  ],
  BE: [
    FEATURE_RETURN_LABELS,
    FEATURE_STORES_PAGE,
    FEATURE_TYPES_PAGE,
    FEATURE_SELL,
    FEATURE_CREDITS,
    FEATURE_HM_COLLAB_BUY,
    FEATURE_PROMOTIONAL_SEARCH_FOOTER,
    FEATURE_DONATION_PAYOUT,
    FEATURE_FLATTERED_COLLAB_SELL,
    FEATURE_SALES_CONTROL
  ],
  EU: [FEATURE_RETURN_LABELS, FEATURE_HM_COLLAB_BUY, FEATURE_SELL_SIGNUP, FEATURE_CREDITS],
  DK: [
    FEATURE_RETURN_LABELS,
    FEATURE_STORES_PAGE,
    FEATURE_TYPES_PAGE,
    FEATURE_CREDITS,
    FEATURE_SELL,
    FEATURE_DONATION_PAYOUT,
    FEATURE_BAG_SUBSCRIPTION,
    FEATURE_HM_COLLAB_BUY,
    FEATURE_BAG_PICKUP,
    FEATURE_PROMOTIONAL_SEARCH_FOOTER,
    FEATURE_FLATTERED_COLLAB_SELL,
    FEATURE_SNAPCHAT_COLLAB_BUY,
    FEATURE_SALES_CONTROL
  ],
  PL: [
    FEATURE_SELL_SIGNUP,
    FEATURE_HM_COLLAB_BUY,
    FEATURE_RETURN_LABELS,
    FEATURE_CREDITS,
    FEATURE_SELL
  ],
  FI: [
    FEATURE_RETURN_LABELS,
    FEATURE_STORES_PAGE,
    FEATURE_TYPES_PAGE,
    FEATURE_CREDITS,
    FEATURE_SELL,
    FEATURE_DONATION_PAYOUT,
    FEATURE_BAG_SUBSCRIPTION,
    FEATURE_HM_COLLAB_BUY,
    FEATURE_BAG_PICKUP,
    FEATURE_FLATTERED_COLLAB_SELL,
    FEATURE_SNAPCHAT_COLLAB_BUY,
    FEATURE_SALES_CONTROL
  ],
  FR: [
    FEATURE_RETURN_LABELS,
    FEATURE_STORES_PAGE,
    FEATURE_TYPES_PAGE,
    FEATURE_CREDITS,
    FEATURE_SELL,
    FEATURE_DONATION_PAYOUT,
    FEATURE_BAG_SUBSCRIPTION,
    FEATURE_PROMOTIONAL_SEARCH_FOOTER,
    FEATURE_FLATTERED_COLLAB_SELL,
    FEATURE_HM_COLLAB_BUY,
    FEATURE_SALES_CONTROL
  ]
}

export const initFeatureToggle = () => {
  window.localStorage.setItem('featureToggle', JSON.stringify(REGION_FEATURES[region()]))
}

export const setFeatureToggle = (features) => {
  window.localStorage.setItem('featureToggle', JSON.stringify(features))
}

export const isFeatureEnabled = (feature) => {
  if (process.env.STORYBOOK_ON) return true
  if (config.environment === 'canary') return true
  if (!ALL_FEATURE_TOGGLES.includes(feature)) return true
  const featureToggles = JSON.parse(window.localStorage.getItem('featureToggle'))
  return feature && featureToggles
    ? featureToggles.includes(feature)
    : REGION_FEATURES[region()].includes(feature)
}
